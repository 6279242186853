<template>
  <div class="main">
       <TheTopbar/>
    <slot />
    <div >
        
        <div class="main-wrapper">
          <div class="d-flex justify-content-between modal-header m-3"> 
                <h5 class="text-white">ADMIN USERS</h5>
                
          </div>
          <div class="d-flex justify-content-between"> 
            <div class="options">
                        <button class="btn btn-main" :class="{ active: state == 'users'}" @click="changeState('users')">USERS</button>
                        <button class="btn btn-main" :class="{ active: state == 'roles'}" @click="changeState('roles')" >ADMIN ROLES</button>
                        <button class="btn btn-main" :class="{ active: state == 'functions'}" @click="changeState('functions')" >ROLE FUNCTIONS</button>  
              </div>
              <div >
                <button class="btn btn-info"  @click="addNewUser()" v-if="!showAddNewUserForm">Add new User</button>
                <button class="btn btn-danger"  @click="addNewUser()" v-if="showAddNewUserForm">Close</button>
              </div>
          </div>
          <div v-if="showAddNewUserForm"> 
            <div class="market-content">
              <label>Create new user</label>
              <p v-if="showMsg">{{ msg }}</p>
              <form> 
                <fieldset> 
                  <div class="box">
                   <input class="form-control" v-model="user.userId" placeholder="enter preferred user ID"/>
                   <input class="form-control" v-model="user.password" placeholder="create  password"/>
                   <input class="form-control" v-model="user.firstName" placeholder="enter  first name"/>
                   <input class="form-control" v-model="user.lastName" placeholder="enter last name"/>
                   <input class="form-control" v-model="user.emailAddress" placeholder="enter a valid email address"/>

                  </div>
                  <button class="btn btn-info" style="float: right !important;" @click="createNewUser">Create User</button>
                </fieldset>
              </form>
                          
              </div>
            </div>
       
            <div class="" v-if="state == 'roles'">
                     <div class="text-white" >
                           <div class="market-content">
                               <table >
                              <thead>
                                <tr class="text-white" >
                                    <th>Role ID</th>
                                    <th>Access Level</th>
                                    <th>Action</th>
                                </tr>
                              </thead>
                                <tbody v-for="profile in adminRoles" :key="profile.id">
                                    <td>{{profile.refCode}}</td>
                                     <td>{{profile.refValue}}</td>
                                    <td><button @click="showAddFunction(profile)" >Add Function</button>|<button @click="showAddUser(profile)" > Users</button></td>
                                </tbody>
                            </table>
                            
                            <!-- <ul class="table" v-if="showUserTable" v-for="user,index in userList" :key="user.id">
                              <label>Users with {{roleId}} role</label>
                              <li>{{userList[index]}}</li>
                               
                            </ul> -->

                           </div>
                     </div>
               </div>

            <div class="" v-if="state == 'functions'">
                     <div class="text-white" >
                           <div class="market-content">
                               <table class="table" >
                                <thead>
                                    <tr class="text-white" >
                                        
                                        <th>Function</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody v-for="profile in adminFunctions" :key="profile.id">
                                    
                                    <td>{{profile.refCode}}</td>
                                    <td>{{profile.refValue}}</td>
                                </tbody>
                            </table>
                           </div>
                     </div>
            </div>
             <div class="" v-if="state == 'users'">
            
                     <div class="" >
                        <div class="market-content">
                               <table class="table">
                                <thead>
                                    <tr class="text-white" >
                                        <th>User Id</th>
                                        <th>Email address</th>
                                        <th>Last Login</th>
                                        <th>Last Update</th>
                                       <th></th>

                                    </tr>
                                </thead>
                                <tbody v-for="profile in adminUser" :key="profile.id" @click="getAdminUserRole(profile)">
                                    <td>{{profile.userId}}</td>
                                    <td>{{profile.emailAddress}}</td>
                                    <td>{{formatDate(profile.lastLogin)}}</td>
                                    <td>{{formatDate(profile.lastUpdate)}}</td>
                                    <td>View</td>
                                    
                                </tbody>
                            </table>
                           </div>
                      </div>
                </div>    
        </div>

    </div>
    <role-modal v-if="showAddModal"  @close="showAddUser(roleId)" :roleId="this.roleId"></role-modal>
    <remove-modal v-if="showRemoveModal" @close="showRemoveRole()"></remove-modal>
    <add-function v-if="showFunctionModal" @close="showAddFunction()" :roleId="this.roleId"></add-function>
    <AdminAction v-if="showAction" @close="getAdminUserRole()" :profile="profile"></AdminAction>
        
    
   
  </div>
</template>
<script>
import TheTopbar from '../components/TheTopbar.vue'
import moment from 'moment'
import RoleModal from '../components/modal/RoleModal.vue'
import RemoveModal from '../components/modal/RemoveModal.vue'
import AddFunction from '../components/modal/AddFunction.vue'
import AdminAction from '../components/modal/AdminAction.vue'



export default {
    name: 'AdminUsers',
    
    components: {
        TheTopbar,
        RoleModal,
        RemoveModal,
        AddFunction,
        AdminAction
          
    },
      data: function(){
          return{
            showUserTable: false,
             adminUser:[],
             adminFunctions:[],
             adminRoles:[],
             functions: false,
             roles: false,
             state: 'roles',
             showAddModal: false,
             showRemoveModal: false,
             showFunctionModal: false,
             roleId:'',
             userList:[],
             userRoles:[],
             userId:"",
             showAction: false,
             profile:{},
             showAddNewUserForm: false,
             user:{
              userId:'',
              password:'',
              firstName:'',
              lastName:'',
              emailAddress:''
             },
            showMsg: false,
            msg:'',
    
          }
    },
   
    computed:{
       
        init() {
          return this.$store.state.init;
        },
        show() {
          return this.$store.state.show;
        },
      
       
    },
  
    
    methods:{
      addNewUser(){
        this.showAddNewUserForm = !this.showAddNewUserForm
      },
      validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
      createNewUser(){
        let hasError = false;
        if (!this.validateEmail(this.user.emailAddress)) {
            this.showMsg = true
            this.msg = "Please provide a valid Email Address.";
            hasError = true;
        }
        if (this.user.userId == '' || this.user.userId == null || this.user.userId == undefined) {
            this.showMsg = true
            this.msg = "Please enter a new userID.";
            hasError = true;
        }
        if (this.user.firstName == '' || this.user.firstName == null || this.user.firstName == undefined) {
            this.showMsg = true
            this.msg = "Please enter the first name.";
            hasError = true;
        }
        if (this.user.lastName == '' || this.user.lastName == null || this.user.lastName == undefined) {
            this.showMsg = true
            this.msg = "Please enter the last name.";
            hasError = true;
        }
        if (this.user.password == '' || this.user.password == null || this.user.password == undefined) {
            this.showMsg = true
            this.msg = "Password is required.";
            hasError = true;
        }

        if(!hasError){
          const params = {
          userId: this.user.userId,
          loginPass: this.user.password,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          emailAddress: this.user.emailAddress

          }
          const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/addAdminUser',
                  data,
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.showAddNewUserForm = false
          
                })
        }
       
      },
      getState() {
            let state = localStorage.getItem("state")
            if(state != "" || state != undefined) {
            this.state = state
            }
        },
      changeState(state) {
            this.state = state
            localStorage.setItem("state", state);
        },

        getAdminRoles(){
          const params = {
            refType: 'CZROLE'
          }
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: 'ax/getRefDataList',
                    params,
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.adminRoles = response.data
          
                })
      },
      showAddFunction(roleId){
        
          this.roleId = roleId
          this.showFunctionModal = !this.showFunctionModal
      },
      getAdminFxn(){
          const params = {
            refType: 'CZFXN'
          }
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: 'ax/getRefDataList',
                    params,
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.adminFunctions = response.data
          
                })
      },
      getAdminUserRole(profile){
          this.profile = profile
          this.showAction = !this.showAction
       
        },
      getAdminUsers(){
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: 'ax/getAllAdminUsers',
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.adminUser = response.data
                  


                   let count = 0;
                   response.data.forEach((value, index) => {
                        this.userId = value.userId
                    
                     
                       count++;

                       if(count == response.data.length ) {

                       }
                    });

                })
        },
        showUserList(roleId){
        this.roleId = roleId
         const params = {
            roleId: roleId

          }
          const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');

          const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/getAdminUsersByRole',
                    data
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.userList = response.data
                  //  this.showUserTable = true
                   
                })
        },
        formatDate: function(date) {
                return moment(date).format('MM/DD/yyyy hh:ss A');
        },
        showAddUser(roleId){
          this.roleId = roleId
          this.showAddModal = !this.showAddModal
        },
   
        showRemoveRole(){
          this.showRemoveModal = !this.showRemoveModal
        },
        addUsers(){
          const params = {
            userId: this.userId,
            roleId: this.roleId

          }
          const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');

          const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/getAdminUsersByRole',
                    data
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.state = 'users'
                })
        },

        
        checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
                    console.log(response.data)
                  
					}).catch((err) => {
						
					if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  

					if(err.response.data.msgCode == '00020') {
						
						window.location.href = "/#/setup-profile";
                   
						
					}  

					})
			},
             

          
    },
    beforeMount(){
         this.checkSession();
         
        
        
    },
    mounted(){
      this.getAdminFxn();
      this.getAdminRoles();
      this.showUserList();
      this.getAdminUsers();
      this.getState();
    }
   
     


        
}
</script>

<style lang="scss" scoped>


.single-slide{
    border: 1px solid white;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
}

  
h6{
    padding-top: 10px;
    color: white;
}
.spinner {
		width: 50px !important;
		margin: 0 !important;
	}
.main-content {
		display: flex; 
	    align-items: center;
	    justify-content: space-between !important;
	    width: 100%;
	}
.left-content {
		display: block;
	    align-items: center;
	    justify-content: center;
        justify-content: space-evenly;
        width: 100% !important;
}
.left-content .graph-wrappper {
	    display: flex; 
		align-items: center;
		justify-content: space-between;
}

.left-content .graph-wrappper .box { 
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

#line-chart{
	  width: 300px !important;
	  height: 400px !important;
	}

.main-container{
		padding: 1px 16px;
		height: auto;
    width: auto !important;
	}
.navbar{
		display: flex;
		width: auto;
        justify-content: flex-start;

	}
ul{
    list-style: none !important;
}
.navbar li a {
		display: block;
		color: #ffff;
		padding: 8px 16px;
		text-decoration: none;
		}

.navbar li a.active {
		color: #bdbdbd;
		}

.navbar li a:hover:not(.active) {
		color: #bfbfbf !important;
		}

.main-wrapper{
    width: 100% !important;
    
}

.market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }

</style>