<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">Add Functions to {{roleId.refCode}}</h5>
            <p @click="closeModal" class="m-2 x">x</p>
        </div>
      <div class="modalBody">
        

        <div class="form-group">
                  Add function
                       <select name="id" @change="onChangeFxn($event)" v-model="fxnidnumber" class="form-select form-control" placeholder="Select Role">
                            <option v-for="id in adminFunctions" :key="id.id" :value="id.refCode">{{id.refCode}} - {{id.refValue}}</option>
                        </select>
                        <p v-if="showMsg">{{msg}}</p>
                        <button v-if="fxnidnumber" type="submit" class="btn btn-cancel" id="saveBtn" @click="addFxnRole()">Add</button>
            </div>
              <div class="text-dark" >
                           <div class="table-responsive">
                               <table class="table" >
                                <thead>
                                    <tr class="text-dark">
                                        <th>Function ID</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody v-for="item,index in functionsPerRole" :key="item.id">
                                    <td>{{functionsPerRole[index]}}</td>
                                    <td><button @click="removeFxnRole(functionsPerRole[index])">remove</button></td>
                                </tbody>
                               
                            </table>
                           </div>
                     </div>
           <div class="" >
                    
            </div>
           
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {

    },
    props:["roleId"],
    data: function() {
        return {
           adminRoles:[],
           userId:'',
           fxnidnumber:'',
           adminFunctions:[],
           functionsPerRole:[],
           showMsg: false,
           msg: "",
           fxnId:""
       

            
        }
    },

   

    methods: {
        onChangeFxn(event){
            this.fxnidnumber = event.target.value;
          },
        closeModal(){
            this.$emit("close")
        },

        done(){
            this.$emit("close") 
            this.$emit("done")
        },
    
        getRefDataList(){
          const params = {
            refType: 'CZFXN'
          }
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: 'ax/getRefDataList',
                    params,
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.adminFunctions = response.data
          
                })
      },
     
        showFxnPerRole(){
            const params = {
                roleId: this.roleId.refCode
            }
            const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');

            const options = {
                        method: 'POST',
                        headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                        url: '/ax/getPermissionsByRole',
                        data
                        };

                    this.axios(options)
                    .then((response) => { 
                    console.log(response.data)
                    this.functionsPerRole = response.data                    
                    })
        },
        addFxnRole(){
           
            const params = {
                roleId: this.roleId.refCode,
                fxnId: this.fxnidnumber,
            }
            const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');

            const options = {
                        method: 'POST',
                        headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                        url: 'ax/addRolePermission',
                        data
                        };

                    this.axios(options)
                    .then((response) => { 
                    console.log(response.data)
                    this.functionsPerRole = response.data
                    location.reload();
                    }).catch((err) => {
                        this.showMsg = true;
                        this.msg = err.response.data.msgText
                          setTimeout(()=> {
                        this.showMsg = false
                        }, 3000)
                    })
    
        },
        removeFxnRole(fxnId){
            this.fxnId = fxnId
            const params = {
                roleId: this.roleId.refCode,
                fxnId: fxnId,
            }
            const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');

            const options = {
                        method: 'POST',
                        headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                        url: '/ax/removeRolePermission',
                        data
                        };

                    this.axios(options)
                    .then((response) => { 
                    console.log(response.data)
                    this.functionsPerRole = response.data
                   location.reload();
                    }).catch((err) => {
                        this.showMsg = true;
                        this.msg = err.response.data.msgText
                          setTimeout(()=> {
                        this.showMsg = false
                        }, 3000)
                    })

        },
          checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
                    // console.log(response.data)
                  
					}).catch((err) => {
						
					if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  

					if(err.response.data.msgCode == '00020') {
						
						window.location.href = "/#/setup-profile";
                   
						
					}  

					})
			},
        
      
     
    },
    beforeMount(){
        this.checkSession();
    },
    mounted(){
        // this.getRoles();
        // this.getFunctions();
        this.showFxnPerRole();
        this.getRefDataList();
    }
}
</script>

<style scoped lang="scss">
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 10px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
    /* z-index: 2; */
    max-height: calc(100vh - 110px);
    overflow-y: auto; 
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:black;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
</style>