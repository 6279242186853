<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">Update  {{profile.userId}}</h5>
            <p @click="closeModal" class="m-2 x">x</p>
        </div>
      <div class="modalBody">
              <div class="text-dark" >
                           <div class="table-responsive">
                               <table class="table" >
                                <tbody>
                                      <tr> <td>User ID</td>:<td>{{ adminDetails.userId }}</td> </tr>
                                      <tr> <td>Email</td>:<td>{{ adminDetails.emailAddress }}</td> </tr>
                                      <tr> <td>First Name</td>:<td>{{ adminDetails.firstName }}</td> </tr>
                                      <tr> <td>Last Name</td>:<td>{{ adminDetails.lastName }}</td> </tr>
                                      <tr> 
                                        <button @click="changePassword()">Change Password</button> <span v-if="showMsg"> {{ msg }}</span>
                                        <div v-if="showChangePassword"><input class="form-control" v-model="newPassword"/> <span><button @click="updatePassword()">Submit</button></span></div>
                                      </tr>
                                      
                                </tbody>
                            </table>
                           </div>
                     </div>

                     <div class="market-content">
                      <div v-if="isLoading" class="loading-spinner"></div>
                               <table >
                              <thead>
                                <tr class="text-white" >
                                    <th>Role ID</th>
                                    <th>Action</th>
                                </tr>
                              </thead>
                                <tbody v-for="profile in adminRoles" :key="profile.id">
                                    <td>{{profile}}</td>
                                     <td><button @click="removeFunction(profile)">Remove</button></td>
                                   
                                </tbody>
                            </table>
                      </div>
                      <div class="form-group mt-4">
                            <p>Roles to add</p>
                            <div v-if="isLoading" class="loading-spinner"></div>
                                <select name="id" @change="onChangeFxn($event)" v-model="fxnidnumber" class="form-select form-control" placeholder="Select Role">
                                      <option v-for="id in adminRolesPerRefType" :key="id.id" :value="id.refCode">{{id.refCode}} - {{id.refValue}}</option>
                                  </select>
                                  <p v-if="showMsg">{{msg}}</p>
                                  <button v-if="fxnidnumber" type="submit" class="btn btn-cancel" id="saveBtn" @click="addRole(fxnidnumber)">Add</button>
                      </div>

                    
           
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {

    },
    props:["profile"],
    data: function() {
        return {
          adminDetails:{},
          adminRoles:[],
          adminRolesPerRefType:[],
          roleId: '',
          showFunctionModal:'',
          fxnidnumber:'',
          showMsg: false,
           msg:'',
           functionsPerRole:'',
           isLoading: false,
           showChangePassword: false,
           newPassword:''

        }
    },

   

    methods: {
      changePassword(){
        this.showChangePassword = true
      },
      updatePassword(){
        const params = {
          newPwd: this.newPassword,
               userId: this.profile.userId,
           }
           const data = Object.keys(params)
                   .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                   .join('&');

           const options = {
                       method: 'POST',
                       headers: { 'content-type': 'application/x-www-form-urlencoded',
                       'vmiadmin-authz': localStorage.getItem("tokenId")  },
                       url: '/ax/changeAdminUserPwd',
                       data
                       };

                   this.axios(options)
                   .then((response) => { 
                   console.log(response.data)
                        this.showMsg = true;
                        this.msg = 'Password has successfully updated.'
                         setTimeout(()=> {
                          this.showMsg = false
                          this.showChangePassword = false
                       }, 3000)
                   }).catch((err) => {
                       this.showMsg = true;
                       this.msg = err.response.data.msgText
                         setTimeout(()=> {
                       this.showMsg = false
                       }, 3000)
                   })
      },
      onChangeFxn(event){
            this.fxnidnumber = event.target.value;
        },
      addRole(){

           const params = {
               roleId: this.fxnidnumber,
               userId: this.profile.userId,
           }
           const data = Object.keys(params)
                   .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                   .join('&');

           const options = {
                       method: 'POST',
                       headers: { 'content-type': 'application/x-www-form-urlencoded',
                       'vmiadmin-authz': localStorage.getItem("tokenId")  },
                       url: 'ax/addAdminUserRole',
                       data
                       };

                   this.axios(options)
                   .then((response) => { 
                   console.log(response.data)
                   this.functionsPerRole = response.data
                   location.reload();
                   }).catch((err) => {
                       this.showMsg = true;
                       this.msg = err.response.data.msgText
                         setTimeout(()=> {
                       this.showMsg = false
                       }, 3000)
                   })
   
       },
        closeModal(){
            this.$emit("close")
        },
        showAddFunction(roleId){
            this.roleId = roleId
            this.showFunctionModal = !this.showFunctionModal
      },
      removeFunction(roleId){
        this.roleId = roleId
        const params = {
          roleId: roleId,
          userId: this.profile.userId
          }
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/removeAdminUserRole',
                    params,
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   location.reload();
          
                })
      },
        getAdminRoles(){
          this.isLoading = true
          const params = {
            refType: 'CZROLE'
          }
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: 'ax/getRefDataList',
                    params,
                    };

				this.axios(options)
				.then((response) => { 
                   this.isLoading = false
                   this.adminRolesPerRefType = response.data
          
                })
      },
      getAdminUser(){
        this.isLoading = true
            const params = {
              userId: this.profile.userId
              }
              const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                        params,
                    url: '/ax/getAdminUser',
                    };

            this.axios(options)
            .then((response) => { 
              this.isLoading = false
                   this.adminDetails = response.data
                  

                })
        },
        showAdminUserRole(){
          this.isLoading = true
            const params = {
              userId: this.profile.userId
              }
              const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                        params,
                    url: '/ax/getAdminUserRoles',
                    };

            this.axios(options)
            .then((response) => { 
              this.isLoading = false
                   this.adminRoles = response.data
                  

                })
        },
        
          checkSession: function() {

            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
            url: '/ax/sessionCheck',
            };

            this.axios(options)
            .then((response) => { 
                      
              }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  

              if(err.response.data.msgCode == '00020') {
                
                window.location.href = "/#/setup-profile";
                      
                
              }  

					})
			},
        
      
     
    },
    beforeMount(){
        this.checkSession();
    },
    mounted(){
      this.showAdminUserRole();
      this.getAdminRoles();
      this.getAdminUser();
    }
}
</script>

<style scoped lang="scss">
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 10px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
    /* z-index: 2; */
    max-height: calc(100vh - 110px);
    overflow-y: auto; 
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:black;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
</style>